import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import { IoIosFolder, IoIosCloudUpload, IoIosCloudDone } from "react-icons/io";
import "./style.css";

export default class JobListing extends Component {
  constructor(props) {
    super(props);
    const { listingId } = props;
    this.state = {
      active: false,
      file: null,
      name: "",
      email: "",
      tel: "",
      loaded: 0,
      listingId,
    };
    this.handleFileAdd = this.handleFileAdd.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  handleFileAdd({ target }) {
    if (target.files[0]) {
      this.setState({
        file: target.files[0],
      });
    }
  }

  handleUpload() {
    const {
      file, name, email, tel, listingId,
    } = this.state;
    if (!file || !email) return false;
    const data = new FormData();
    data.append("file", file, file.name);
    data.append("name", name);
    data.append("email", email);
    data.append("tel", tel);
    data.append("listingId", listingId);

    axios.post(
      "https://h4ne5hsepj.execute-api.eu-west-2.amazonaws.com/Prod/curricula",
      data,
      {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      },
    );
    return "success";
  }

  handleInputChange({ target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  toggleActive() {
    const active = !this.state.active;
    this.setState({ active });
  }

  render() {
    const {
      jobTitle, jobDescription, listingId, longDescription,
    } = this.props;
    const {
      active, file, name, email, tel, loaded,
    } = this.state;
    return (
      <div className="Job-listing">
        <h3>{jobTitle}</h3>
        <p>{jobDescription}</p>
        <input
          type="submit"
          value="See More"
          className={`Contact-button ${active
            && "Job-listing-contact-button-hidden"}`}
          onClick={this.toggleActive}
        />
        <div
          className={`Job-application ${active && "Job-application-active"}`}
        >
          {longDescription.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <form id={`application-for-role-${listingId}`}>
            <label>
              Name
              <input
                type="text"
                name="name"
                onChange={this.handleInputChange}
                value={name}
                disabled={!!loaded}
              />
            </label>
            <label>
              Email*
              <input
                type="email"
                name="email"
                required
                onChange={this.handleInputChange}
                value={email}
                disabled={!!loaded}
              />
            </label>
            <label>
              Number
              <input
                type="tel"
                name="tel"
                onChange={this.handleInputChange}
                value={tel}
                disabled={!!loaded}
              />
            </label>
            <label>
              CV*
              <div className="File-input">
                {file ? (loaded < 1 ? file.name : `${loaded}%`) : "Upload CV"}
                &nbsp;
                {file
                  ? loaded !== 100
                    ? // <IoIosCloudUpload />
                    "up"
                    : // <IoIosCloudDone />
                    "done"
                  : // <IoIosFolder />
                  "folder"}
                &nbsp;
              </div>
              <input
                onInput={this.handleFileAdd}
                type="file"
                name="cv"
                accept="application/msword, text/plain, application/pdf"
                disabled={!!loaded}
              />
            </label>
            <input
              type="text"
              placeholder="Don't fill this if you are human"
              className="fpl__input-robit"
              name="robit"
            />
            {!loaded ? (
              <input
                type="submit"
                value="Submit"
                className="Contact-button"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleUpload();
                }}
              />
            ) : (
              <p>
                Thank you for sending us your application, we'll be in touch
                shortly
              </p>
            )}
          </form>
        </div>
      </div>
    );
  }
}

JobListing.propTypes = {
  jobTitle: PropTypes.string.isRequired,
  jobDescription: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
  longDescription: PropTypes.arrayOf(PropTypes.string),
};

JobListing.defaultProps = {
  longDescription: [
    "We benefit most when we bring new people and ideas. So we don’t have a list of mandatory qualifications or skills for any of our roles, just bring your best self.",
    "We want to help you grow, learn, and to be the best version of you possible.",
    "Farpoint Labs offer our employees a safe and inclusive work environment where we constantly challenge our people to be the best they can.",
  ],
};
