import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import JobListing from "../components/jobListing";
import SafeZone from "../components/safeZone";
import ParallaxImage from "../components/parallaxImage";
import { BLACK } from "../utils/constants";

// import "./style.css";

export default class extends Component {
  constructor() {
    super();

    this.state = {
      filter: "",
      openings: [
        {
          listingId: "jsSeH0fax4",
          jobTitle: "JavaScript Engineer",
          jobDescription:
            "Know your monad theory, love mathematically pure code? Then we'd love to talk to you. JavaScript is the best language, as we all know. Experience with React is a boon.",
          longDescription: [
            "Some of the things we're looking for:",
            "Web service software architecture and design.",
            "Software specification and documentation.",
            "Working closely with product owners, to develop concepts and prototypes for new technologies, products and applications.",
            "Mentoring & coaching less experienced team members. Helping to develop and promote appropriate processes and best practice within the team.",
          ],
        },
        {
          listingId: "doK8C3fd0",
          jobTitle: "DevOps Engineer",
          jobDescription:
            "Know GoLang, Kubernetes, and cloud? We have an exciting challenge for you on our team. Enjoy taking our clients on their cloud journey, and implement the latest and greatest technologies.",
        },
        {
          listingId: "ocF9S3as3",
          jobTitle: "OCAML Engineer",
          jobDescription:
            "Know your monad theory, love mathematically pure code? Then we'd love to talk to you. Want to build high availability scalable systems and solve difficult mathematical challenges, we have exactly what you're after.",
          longDescription: [
            "Some of the things we're looking for:",
            "Web service software architecture and design.",
            "Software specification and documentation.",
            "Working closely with product owners, to develop concepts and prototypes for new technologies, products and applications.",
            "Mentoring & coaching less experienced team members. Helping to develop and promote appropriate processes and best practice within the team.",
          ],
        },
        {
          listingId: "poH3A0vr6",
          jobTitle: "Product Owner",
          jobDescription:
            "Do you dream in products? Have you got the drive and vision to help our clients figure out how to help their customers? We need amazing product owners like you. Join us and help make the difference to our clients",
        },
      ],
    };

    this.filterBy = this.filterBy.bind(this);
  }

  filterBy({ target }) {
    this.setState({ filter: target.value.toLowerCase() });
  }

  render() {
    const { filter, openings } = this.state;
    const filteredOpenings = openings
      .filter(
        ({ jobTitle, jobDescription }) =>
          jobTitle.toLowerCase().includes(filter)
          || jobDescription.toLowerCase().includes(filter),
      )
      .map((opening) => (
        <JobListing key={`${opening.listingId}`} {...opening} />
      ));
    return (
      <Layout>
        <SEO title="Join Us" />
        <ParallaxImage
          height="halfScreen"
          url="https://images.unsplash.com/photo-1501497849301-66eb0889c6a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
        >
          <div className="Join-us-page">
            <SafeZone style={{ backgroundColor: BLACK, marginTop: "100px" }}>
              <div className="Intro-heading Center-vertical">
                <h1>Start your journey with Farpoint Labs</h1>
              </div>
              <div className="Join-us-subtitle">
                <p>
                  Fancy yourself a JavaScript wizard or a DevOps ninja? Can you
                  manage products, or coach agile. We&apos;d love to have you on
                  our team.
                </p>
                <p>
                  We&apos;re always looking for top talent. If you don&apos;t
                  see a role that suits you, but you&apos;d love to work with us
                  send a CV to
                  {" "}
                  <a href="mailto:join-us@farpointlabs.com">
                    join-us@farpointlabs.com
                  </a>
                </p>
                <p>
                  At Farpoint Labs you&apos;ll have the freedom to chose
                  technologies, and to make product and engineering decisions. You will
                  be a valued member of our team with autonomy shape your
                  destiny.
                </p>
              </div>
              <div>
                <input
                  type="safezone"
                  onChange={this.filterBy}
                  placeholder="Search our openings"
                />
              </div>
            </SafeZone>
            <div className="Openings">
              <div className="Join-us-job-listings">
                {filteredOpenings.length > 0 ? (
                  filteredOpenings
                ) : (
                  <div className="Join-us-not-found">
                    <h3>
                      Sorry, we don&apos;t seem have any listings that match
                      your criteria
                    </h3>
                    <p>
                      We are always looking for the best people to fill a
                      variety of interesting roles and challenging roles.
                      <br />
                      <br />
                      Even if we don&apos;t have an opening that suits you right
                      now, we woud love to hear from you. Get in touch at
                      {" "}
                      <a href="mailto:join-us@farpointlabs.com">
                        join-us@farpointlabs.com
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ParallaxImage>
      </Layout>
    );
  }
}
